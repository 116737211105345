import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { FeaturesGet, FeatureSet } from '../actions/features.action';
import { FeatureService } from '../services/feature.service';
import { FeatureModel } from '../models/feature.model';
import { environment } from '../../environments/environment';

export class FeatureStateModel {
  features: FeatureModel;
}

@State<FeatureStateModel>({
  name: 'featuresState',
  defaults: {
    features: environment.features
  }
})
@Injectable()
export class FeaturesState {
  constructor(private featureService: FeatureService) {}

  @Selector()
  static selectFeatures(state: FeatureStateModel) {
    return state.features;
  }

  @Action(FeaturesGet)
  featuresGet(ctx: StateContext<FeatureStateModel>) {
    return this.featureService.get().pipe(
      tap((returnData) => {
        const state = ctx.getState();
        ctx.setState({
          ...state,
          features: returnData //here the data coming from the API will get assigned to the users variable inside the appstate
        });
      })
    );
  }

  @Action(FeatureSet)
  featureSet(ctx: StateContext<FeatureStateModel>, { name, value }: FeatureSet) {
    const features = this.featureService.setValue(name, value);
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      features
    });
  }
}
