import { Component, OnInit } from '@angular/core';
import { FeatureModel } from 'src/@hop/models/feature.model';
import { Select, Store } from '@ngxs/store';
import { FeaturesState } from '../../states/features-state.service';
import { Observable } from 'rxjs';
import { FeatureSet } from '../../actions/features.action';

@Component({
  selector: 'app-hop-debug-tool',
  templateUrl: './hop-debug-tool.component.html',
  styleUrls: ['./hop-debug-tool.component.scss']
})
export class HopDebugToolComponent implements OnInit {
  @Select(FeaturesState.selectFeatures) features$: Observable<FeatureModel>;
  activate: boolean = false;
  constructor(private store: Store) {}

  ngOnInit(): void {}

  setFeatureValue(name, value) {
    this.store.dispatch(new FeatureSet(name, value));
  }
}
