import { FeatureModel } from '../@hop/models/feature.model';

const mainUrl = 'https://www.topsejur.ro';
const sentryUrl = 'https://ab72a73e4c304d849d6e54666cfa38be@o1427981.ingest.sentry.io/4503907031318528';
const isActivatedSentry = true;

export const environment = {
  production: true,
  apiUrl: mainUrl + '/api',
  apiUploadUrl: mainUrl + '/api',
  apiSocketUrl: mainUrl,
  mainUrl,
  sentryUrl,
  isActivatedSentry,
  features: new FeatureModel({ isDebug: false, isTest: true })
};
