import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import {
  MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatLegacyFormFieldDefaultOptions as MatFormFieldDefaultOptions
} from '@angular/material/legacy-form-field';
import { Utils } from './services/utils';
import { NgxsModule } from '@ngxs/store';
import { FeaturesState } from './states/features-state.service';

@NgModule({
  imports: [CommonModule, LayoutModule, NgxsModule.forFeature([FeaturesState])],
  exports: [LayoutModule],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    },
    Utils
  ]
})
export class HopModule {}
