import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../../@hop/layout/layout.module';
import { FrontCustomLayoutComponent } from './front-custom-layout.component';
import { SidenavModule } from '../../@hop/layout/sidenav/sidenav.module';
import { ToolbarModule } from '../../@hop/layout/toolbar/toolbar.module';
import { FooterModule } from '../../@hop/layout/footer/footer.module';
import { ConfigPanelModule } from '../../@hop/components/config-panel/config-panel.module';
import { SidebarModule } from '../../@hop/components/sidebar/sidebar.module';
import { QuickpanelModule } from '../../@hop/layout/quickpanel/quickpanel.module';

@NgModule({
  declarations: [FrontCustomLayoutComponent],
  imports: [CommonModule, LayoutModule, SidenavModule, ToolbarModule, FooterModule, ConfigPanelModule, SidebarModule, QuickpanelModule]
})
export class FrontCustomLayoutModule {}
