import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HopModule } from '../@hop/hop.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { AuthenticationService } from './_services';
import { appInitializer, ErrorInterceptor, JwtInterceptor } from './_helpers';
import { ConfirmDialogComponent } from '../@hop/components/confirm-dialog/confirm-dialog.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { HopDebugToolModule } from 'src/@hop/components/hop-debug-tool/hop-debug-tool.module';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import packageInfo from '../../package.json';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { FeaturesState } from '../@hop/states/features-state.service';
import { UserState } from './states/user-state.service';
import { FrontCustomLayoutModule } from './front-layout/front-custom-layout.module';

if (environment.isActivatedSentry) {
  Sentry.init({
    dsn: environment.sentryUrl,
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', `${environment.apiUrl}`],
        routingInstrumentation: Sentry.routingInstrumentation
      })
    ],
    release: packageInfo.version,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

@NgModule({
  declarations: [AppComponent, ConfirmDialogComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxsModule.forRoot([UserState]),
    NgxsLoggerPluginModule.forRoot({ collapsed: false, disabled: environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    // Hop
    HopModule,
    CustomLayoutModule,
    FrontCustomLayoutModule,
    MatDialogModule,
    MatButtonModule,
    HopDebugToolModule
    // MatInputModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
