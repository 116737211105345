import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../@hop/services/layout.service';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'hop-front-custom-layout',
  templateUrl: './front-custom-layout.component.html',
  styleUrls: ['./front-custom-layout.component.scss']
})
export class FrontCustomLayoutComponent implements OnInit {
  isDesktop$ = this.layoutService.isDesktop$;

  constructor(private layoutService: LayoutService, private breakpointObserver: BreakpointObserver, private router: Router) {}

  ngOnInit() {}
}
