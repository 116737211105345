<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
<div class="stickyDebug" *ngIf="(features$ | async).isDebug">
  <div class="debugTool" *ngIf="activate">
    <button mat-flat-button color="warn" [matMenuTriggerFor]="aboveMenu">Features</button>
    <mat-menu #aboveMenu="matMenu" yPosition="above">
      <section *ngFor="let value of features$ | async | keyvalue" class="example-section--column">
        <mat-checkbox [checked]="value.value" (click)="setFeatureValue(value.key, !value.value)" class="example-margin">{{
          value.key
        }}</mat-checkbox>
      </section>
    </mat-menu>
  </div>

  <div>
    <button (click)="activate=!activate" mat-fab color="warn"><mat-icon>build</mat-icon></button>
  </div>
</div>
