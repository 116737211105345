import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { UserGet, UserUpdate } from '../actions/user.action';
import { User } from '../_models';
import { UserService } from '../_services';

export class UserStateModel {
  user: User;
}

@State<UserStateModel>({
  name: 'userState',
  defaults: {
    user: null
  }
})
@Injectable()
export class UserState {
  constructor(private userService: UserService, private store: Store) {
    //this.store.dispatch(new UserGet()).subscribe();
  }

  @Selector()
  static selectUser(state: UserStateModel) {
    return state.user;
  }

  @Action(UserGet)
  userGet(ctx: StateContext<UserStateModel>) {
    return this.userService.getUser().pipe(
      tap((returnData) => {
        const state = ctx.getState();

        ctx.setState({
          ...state,
          user: returnData //here the data coming from the API will get assigned to the users variable inside the appstate
        });
      })
    );
  }
  @Action(UserUpdate)
  userSet(ctx: StateContext<UserStateModel>, user: UserUpdate) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      user: user.user
    });
  }
}
