export class FeatureModel {
  isDebug = false;
  isTest = false;

  constructor(featureInput: any) {
    for (const [key, value] of Object.entries(featureInput)) {
      if (this[key] !== typeof undefined) {
        this[key] = value;
      }
    }
  }
}
