import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { ConfigService } from '../@hop/services/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@hop/services/navigation.service';
import icLayers from '@iconify/icons-ic/twotone-layers';
import { LayoutService } from '../@hop/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@hop/services/splash-screen.service';
import { Style, StyleService } from '../@hop/services/style.service';
import { ConfigName } from '../@hop/interfaces/config-name.model';
import icBook from '@iconify/icons-ic/twotone-book';
import icAssigment from '@iconify/icons-ic/twotone-assignment';
import icLocationCity from '@iconify/icons-ic/twotone-location-city';
import icMyLocation from '@iconify/icons-ic/twotone-my-location';
import icVerifiedUser from '@iconify/icons-ic/twotone-verified-user';
import icGrade from '@iconify/icons-ic/twotone-grade';
import icAssignment from '@iconify/icons-ic/assignment';
import icSearch from '@iconify/icons-ic/twotone-search';
import icContacts from '@iconify/icons-ic/twotone-contacts';
import { FeatureService } from 'src/@hop/services/feature.service';
import { Store } from '@ngxs/store';
import { UserGet } from './actions/user.action';

@Component({
  selector: 'hop-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'hop';

  constructor(
    private configService: ConfigService,
    private styleService: StyleService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private featureService: FeatureService,
    private store: Store
  ) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    showConfigButton: false,
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */

    this.route.queryParamMap
      .pipe(map((queryParamMap) => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl'))))
      .subscribe((isRtl) => {
        this.document.body.dir = isRtl ? 'rtl' : 'ltr';
        this.configService.updateConfig({
          rtl: isRtl
        });
      });

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has('layout')))
      .subscribe((queryParamMap) => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has('style')))
      .subscribe((queryParamMap) => this.styleService.setStyle(queryParamMap.get('style') as Style));

    this.navigationService.items = [
      {
        type: 'link',
        label: 'Dashboard',
        route: '/',
        icon: icLayers
      },
      {
        type: 'subheading',
        label: 'Menu',
        children: [
          {
            type: 'link',
            label: 'Locatii',
            route: '/apps/locations',
            icon: icLocationCity
          },
          {
            type: 'link',
            label: 'Hoteluri',
            route: '/apps/accommodations',
            icon: icLocationCity
          },
          {
            type: 'link',
            label: 'Camere',
            route: '/apps/rooms',
            icon: icLocationCity
          },

          {
            type: 'link',
            label: 'Mese',
            route: '/apps/meals',
            icon: icLocationCity
          },
          {
            type: 'link',
            label: 'Rezervari',
            route: '/apps/reservations',
            icon: icAssignment
          },
          {
            type: 'link',
            label: 'Pagini',
            route: '/apps/pages',
            icon: icGrade
          },
          /* {
            type: 'link',
            label: 'Aio',
            route: '/apps/aio-table',
            icon: icLocationCity
          },
          {
            type: 'link',
            label: 'Hoteluri',
            route: '/apps/aio-table',
            icon: icVerifiedUser
          },
          {
            type: 'link',
            label: 'Editor',
            route: '/apps/editor',
            icon: icVerifiedUser
          }, */
          {
            type: 'link',
            label: 'Utilizatori',
            route: '/apps/users',
            icon: icContacts
          }
        ]
      }
    ];
    this.store.dispatch(new UserGet()).subscribe();
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.featureService.setCookie(params);
    });
  }
}
