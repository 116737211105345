<ng-template #toolbarRef>

</ng-template>

<ng-template #footerRef>
  <hop-footer class="hop-footer"></hop-footer>
</ng-template>


<hop-front-layout
  [footerRef]="footerRef"
  [toolbarRef]="toolbarRef"
></hop-front-layout>
